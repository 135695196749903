import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../components/UIComponent/TextInput";
import ValidationText from "../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../components/ImageUpload/UploadImage";
import { CommonValidationMessages, ErrorMessage } from "../../utils/Messages";
import { validate, isValidForm } from "../../utils/validation/CommonValidator";
import { checkParameters } from "../../utils/Encryption";

// Import API Service
import DtsmeServices from "../../services/axiosServices/apiServices/DtsmeServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import CKClassicEditor from "../../components/ckEditor/CKClassicEditor";

export default class AddEditSolutionProviders extends Component {
    constructor(props) {
        super(props);
        this.dtsmeServices = new DtsmeServices();
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.state = {
            isLoading: false,
            redirect: null,
            solutionProviderId: 0,
            solutionProviderDetails: {
                solutionProviderId: 0,
                companyName: "",
                linkedIn: "",
                website: "",
                description: "",
                companyLogo: 0,
            },
            validationRule: {
                companyName: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "company name"
                        ),
                    },
                ],
                linkedIn: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "linkedIn id"
                        ),
                    },
                ],
                website: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "website name"
                        ),
                    },

                ],
                description: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "description"
                        ),
                    },
                ],
                companyLogo: [
                    {
                        type: "require",
                        message: CommonValidationMessages.UploadPdfRequired.replace("{0}", "Comapnay Logo"),
                    }
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    componentDidMount = () => {
        let params = new URLSearchParams(window.location.search);
        let solutionProviderInformationId = params.get("");
        if (solutionProviderInformationId && (solutionProviderInformationId = checkParameters(solutionProviderInformationId, "Number"))) {
            if (solutionProviderInformationId > 0) {
                this.setState({ solutionProviderId: solutionProviderInformationId }, () => {
                    this.getSolutionProviderInformationById(solutionProviderInformationId);
                })
            }
        }

    }

    // Add Edit DTSME SolutionProviders Detail
    addEditSolutionProvidersInformation = () => {
        let isValidInput = this.isValidateAllFields();
        if (isValidInput) {
            this.setState({ isLoading: true });
            this.dtsmeServices.addEditSolutionProvidersInformation(this.state.solutionProviderDetails)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success("Solution Provider Add sucessfully!");
                        this.setState({ redirect: "/dtsme/SolutionProviders" });

                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
        }
    };



    // Get SolutionProvider details by id
    getSolutionProviderInformationById = (id) => {
        if (this.state.solutionProviderId != null) {
            this.dtsmeServices.getSolutionProviderInformationById(id).then((response) => {
                console.log("By Id response :", response);
                if (response.statusCode === 200 && response.responseItem != null) {
                    let res = response.responseItem.responseContent;
                    this.setState({ solutionProviderDetails: res });
                } else {
                    this.swalServices.Error(response.message);
                }
            }
            );
        }
    };

    // Upload Brochure File Method
    onFileChange = (event, identity) => {
        //debugger
        const file = event.target.files[0];
        // if (identity === "Upload_Certificate") {
        if (file && file.type === "application/pdf") {
            let uploadFile = { ...this.state.resFile };
            uploadFile.filename = file.name;
            uploadFile["file"] = file;
            uploadFile.storagePath = "DT-SME";
            let detail = { ...this.state.solutionProviderDetails };
            detail.brochureFilePath = file.name;
            this.setState({ solutionProviderDetails: detail },
                () => this.validateField("brochureFilePath")
            );
            this.commonServices.uploadPDF(uploadFile).then((response) => {
                if (response != null) {
                    let detail = { ...this.state.solutionProviderDetails };
                    detail.brochureFilePath = response.fileName;
                    this.setState({ solutionProviderDetails: detail });
                    this.swalServices.Success("File Uploaded");
                }
                else {
                    this.swalServices.Error(response.message);
                }
            })
        }
        else {
            this.swalServices.Error("Please Upload only PDF file !");
        }
        // }      
    };

    // Method for Upload Company Logo
    saveMainImage = (file, keyId, imageIdentity) => {
        this.setState({ isLoading: true });
        file.storagePath = "DT-SME\\MarketPlaceCompanyProfileLogo";
        this.commonServices.storeBase64Image(file).then((response) => {
            console.log("Check Image Upload Response :", response);
            if (response != null) {
                this.swalServices.Success("File Uploaded");
                // if (file.storagePath === "DT-SME\\MarketPlaceCompanyProfileLogo") {
                let detail = this.state.solutionProviderDetails;
                detail.companyLogo = response.fileName;
                this.setState({ solutionProviderDetails: detail }, () => this.validateField("companyLogo"));
                // }
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false })
            console.log("this.state.solutionProviderDetails :", this.state.solutionProviderDetails);
        })
    }


    // Internal handling Methods
    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.solutionProviderDetails;
        detail[name] = value;
        this.setState({ solutionProviderDetails: { ...detail } });
    }

    // Field validation method 
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.solutionProviderDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.solutionProviderDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    setEditorData = (data) => {
        if (typeof data === 'string') {
            let detail = this.state.solutionProviderDetails;
            detail["description"] = data;
            this.setState({ solutionProviderDetails: detail });
        }
    };


    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="main-body-section global-account-sec">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                Solution Providers Details
                            </h4>
                        </div>
                        <div className="account-fields">
                            <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                                <div className="grid grid-cols-12 gap-6 w-full ">
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Company Name
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="companyName"
                                                            value={this.state.solutionProviderDetails.companyName}
                                                            identity="companyName"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("companyName")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.companyName} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    LinkedIn
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="linkedIn"
                                                            value={this.state.solutionProviderDetails.linkedIn}
                                                            identity="linkedIn"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("linkedIn")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.linkedIn} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Website
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="dropdwn-input flex items-center">
                                                    <div className="w-full">
                                                        <TextInput
                                                            placeholder=""
                                                            type="text"
                                                            name="website"
                                                            value={this.state.solutionProviderDetails.website}
                                                            identity="website"
                                                            handleChange={this.handleChange.bind(this)}
                                                            onBlur={(e) => this.validateField("website")}
                                                        />
                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.website} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6 w-full ">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Company Logo
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className="w-[320px]">
                                                    <div className="company-logo-cropper ml-auto mr-auto goglobal-company-logo">
                                                        <ImageCropperWithUpload
                                                            key="Main"
                                                            height={360}
                                                            width={480}
                                                            isCheckValidation={true}
                                                            MaxFileSize={2097152}
                                                            MaxFileSizeMessage={"2MB"}
                                                            onSaveImage={this.saveMainImage}
                                                            imageIdentity="DT-SME\MarketPlaceCompanyProfileLogo"
                                                            intitImage={this.state.solutionProviderDetails.companyLogo}
                                                            aspactRatio={480 / 360}
                                                            uploadBtn={"Upload Logo"}
                                                            dimensionText={"(Format: JPG/PNG Dimension: 480px by 360px Maximum Size: 2MB)"}
                                                        />

                                                    </div>
                                                </div>
                                                <ValidationText error={this.state.validState.error.companyLogo} />
                                            </div>
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#757575] font-bold mb-3">
                                                    Description {""}
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                {/* <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="description"
                                                    value={this.state.solutionProviderDetails.description}
                                                    identity="description"
                                                    handleChange={this.handleChange.bind(this)}
                                                    onBlur={(e) => this.validateField("description")}
                                                /> */}
                                                <CKClassicEditor
                                                    key={"Workshop_About_CkEditor"}
                                                    onChange={(data) => {
                                                        this.setEditorData(data);
                                                    }}
                                                    data={this.state.solutionProviderDetails.description}
                                                />
                                                <ValidationText error={this.state.validState.error.description} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-end space-x-10 mt-12 pb-8">
                                    <div className="space-x-4">
                                        {
                                            this.state.isLoading ?
                                                <ButtonLoader />
                                                :
                                                <button
                                                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                                                    onClick={() => this.addEditSolutionProvidersInformation()}
                                                >
                                                    Save
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}