import { axiosGetAuthorize, axiosPostWithoutEncryption } from '../AxiosRequests';
import { GetAllAreaOfExpertise, GetAllBusinessSectors, GetAllChapters, GetAllCountries, GetAllStatus, uploadPDF, StoreBase64Image, UploadReconciliationExcel, UploadVideoToS3Bucket } from '../ApiEndPoints';

export default class CommonServices {

    async getAllAreaOfExpertise() {
        return axiosGetAuthorize(GetAllAreaOfExpertise);
    }

    async getAllBusinessSectors() {
        return axiosGetAuthorize(GetAllBusinessSectors);
    }

    async getAllChapters() {
        return axiosGetAuthorize(GetAllChapters);
    }

    async getAllCountries() {
        return axiosGetAuthorize(GetAllCountries);
    }
    async getAllStatus() {
        return axiosGetAuthorize(GetAllStatus);
    }
    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);
        return axiosPostWithoutEncryption(uploadPDF, formData, true);
    }

    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async uploadReconciliationExcel(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("fileName", request.fileName);
        formData.append("fileTypeExtension", request.fileTypeExtension);
        return axiosPostWithoutEncryption(UploadReconciliationExcel, formData, true);
    }

    async uploadVideoToS3Bucket(request) {
        let formData = new FormData();
        formData.append("key", request.key);
        formData.append("file", request.filePath);        
        formData.append("folder", request.folder);
        return axiosPostWithoutEncryption(UploadVideoToS3Bucket, formData, true);
    }

}