import React, { useEffect, useRef } from 'react';
import ImageCropperWithUpload from '../ImageUpload/UploadImage';
import ValidationText from '../../utils/validation/ValidationText';
import CKClassicEditor from '../ckEditor/CKClassicEditor';

export default function ResourceImageDescription(props) {
    // const editorRef = useRef();
    // useEffect(() => {
    //     editorRef.current = {
    //         CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
    //         ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
    //     };
    // }, []);


    //TODO : Nigam : need to refactor this
    const setDescription = (value, resourceDescriptionId, descriptionImage, editor) => {
        if (typeof value === 'string') {
            let length = value.length;
            let arr =
            {
                resourceDescriptionId: resourceDescriptionId + 1,
                resourceDescription: value,
                resourceDescriptionImage: descriptionImage,
                validate: {
                    description: (value !== "") ? true : false,
                    descriptionLength: (value.length ) ? true : false,
                    // resourceDescriptionImage: (descriptionImage !== "") ? true : false,
                }
            };
            props.setImageDescription(arr, resourceDescriptionId);
        }
    }

    const addDescriptionImage = (file, keyId, imageIdentity, index) => {
        props.onSaveImage(file, keyId, imageIdentity, index);
    }

    return (

        <div>{(props?.pageDescription.map((value, index) => {
            return (
                <div className='grid grid-cols-12 gap-6 relative pt-4' key={"description_" + index}>
                    {
                        (index > 0) ?
                            <span className='absolute top-[-7px] right-[-10px] fa fa-times text-gray-400' onClick={() => props.removeSection(value.resourceDescriptionId)}></span>
                            :
                            null
                    }
                    <div className='2xl:col-span-8 lg:col-span-8 col-span-12   post-details'>

                        {/* <MyEditor key={index}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data, index, value.resourceDescriptionImage);
                            }}
                            data={value.resourceDescription}
                        /> */}
                        <CKClassicEditor
                            key={index}
                            onChange={(data, editor) => { setDescription(data, index, value.resourceDescriptionImage, editor) }}
                            data={value.resourceDescription}
                        />
                        {
                            (value.validate && !value.validate.description) ? <ValidationText
                                error={"Please enter description"}
                            /> : null
                        }
                        {
                            (value.validate && !value.validate.descriptionLength && value.validate.description) ? <ValidationText
                                error={"You have exceeded maximum word limit for description"}
                            /> : null
                        }

                    </div>
                    <div className='2xl:col-span-4 lg:col-span-4 col-span-12 pl-8'>
                        <div className='img-logo-upload text-center'>
                            <ImageCropperWithUpload
                                keyId={index}
                                height={290}
                                width={540}
                                isCheckValidation={true}
                                MaxFileSize={2097152}
                                MaxFileSizeMessage={"2MB"}
                                onSaveImage={addDescriptionImage}
                                imageIdentity="ResourceDescription"
                                intitImage={value.resourceDescriptionImage}
                                aspactRatio={1080 / 580}
                                uploadBtn={"Upload Image"}
                                dimensionText={"(Dimension: 1080px by 580px Maximum Size: 2MB)"}
                            ></ImageCropperWithUpload>
                            {

                                //   (value.validate &&   !value.validate.resourceDescriptionImage) ?  <ValidationText
                                //     error={"Please select image"}
                                //   />:null
                            }
                        </div>
                    </div>
                </div>
            )
        }))}</div>
    )
}
